import user from "@/api/authorize/user-api";
import {
    transform,
    groupBy,
    uniq,
    map,
    head,
    capitalize,
    sortBy,
    lowerCase,
    isEmpty
} from "lodash";

import { baseState, baseMutations } from "@/store/mixins";

const getPermissionOrder = suffix => {
    switch (lowerCase(suffix)) {
        case "view":
            return 0;

        case "create":
            return 1;

        case "update":
            return 2;

        case "delete":
            return 3;

        default:
            return 4;
    }
};

export const state = {
    ...baseState,
    userRole: [],
    permissions: [],
    branches: [],
    employees: [],
    searchPermissionText: ""
};
export const getters = {
    permissionGroups: state => {
        const permissions = state.permissions.filter(p => {
            return (
                p.name
                    .toLowerCase()
                    .includes(state.searchPermissionText.toLowerCase()) ||
                (p.group !== null &&
                    p.group
                        .toLowerCase()
                        .includes(state.searchPermissionText.toLowerCase()))
            );
        });

        return transform(
            groupBy(permissions, permission => capitalize(permission.group)),
            (result, value, key) => {
                result[key] = groupBy(
                    sortBy(value, p =>
                        getPermissionOrder(head(p.name.split("-")))
                    ),
                    permission => capitalize(permission.resource)
                );
            },
            {}
        );
    },
    permissionColumns: state => {
        return sortBy(
            uniq(
                map(state.permissions, p => {
                    return capitalize(head(p.name.split("-")));
                })
            ),
            p => getPermissionOrder(p)
        );
    }
};
export const mutations = {
    ...baseMutations,
    RECEIVE_PERMISSIONS(state, permissions) {
        state.permissions = permissions.map(permission => {
            return {
                id: permission.id,
                name: permission.name,
                resource: permission.label,
                group: permission.group
            };
        });
    },
    SET_SEARCH_PERMISSION(state, searchPermissionText) {
        state.searchPermissionText = searchPermissionText;
    },
    SET_USER_ROLE(state, data) {
        state.userRole = data;
    },
    SET_BRANCHES(state, data) {
        state.branches = data;
    },
    SET_EMPLOYEES(state, data) {
        state.employees = data;
    },
    CLEAR_RESOURCES(state) {
        state.userRole = [];
        state.permissions = [];
        state.branches = [];
        state.employees = [];
    }
};
export const actions = {
    fetch(context, attributes = {}) {
        let params = Object.assign(
            {
                page: context.state.pagination.page,
                perPage: context.state.pagination.perPage,
                filter: {
                    search: context.state.search
                }
            },
            attributes
        );
        return user.get({ params }).then(response => {
            context.commit("RECEIVE_RESOURCES", response);
            return Promise.resolve(response);
        });
    },
    store(context, data) {
        return user.store(data);
    },
    update(context, { id, ...data }) {
        return user.update(id, data);
    },
    async find(context, id) {
        if (isEmpty(context.state.edit_data)) {
            let response = await user.find(id);
            context.commit("SET_EDIT_DATA", response.data);
        }
    },
    async getUserRole(context) {
        let response = await user.userRole();
        context.commit("SET_USER_ROLE", response.data);
    },
    async getBranches(context) {
        let response = await user.branch();
        context.commit("SET_BRANCHES", response.data);
    },
    async getEmployees(context, attributes = {}) {
        let response = await user.employee({
            params: Object.assign({}, attributes)
        });
        context.commit("SET_EMPLOYEES", response.data);
    },
    getPermissions(context, attributes) {
        if (context.state.permissions.length > 0) return;
        return user
            .permission({
                params: Object.assign({}, attributes)
            })
            .then(response => {
                context.commit("RECEIVE_PERMISSIONS", response.data);
                return Promise.resolve(response);
            });
    },
    getCurrentUser(context, attributes = {}) {
        return user.currentUser({ params: attributes });
    },
    getBranchAssigned(context, attributes = {}) {
        return user.branchAssigned({ params: attributes });
    },
    getBranchAssignedForFilter(context, attributes = {}) {
        return user.getBranchAssignedForFilter({ params: attributes });
    },
    changeDefaultBranch(context, data = {}) {
        return user.changeDefaultBranch(data);
    },
    unlock(context, data) {
        return user.unlock(data);
    },
    changePassword(context, data) {
        return user.changePassword(data);
    },
    passwordReset(context, { id, data }) {
        return user.passwordReset(id, data);
    },
    exportExcel(context, data) {
        return user.exportExcel(data)
    },
    getAppUrl(context, attributes = {}) {
        return user.appUrl({ params: attributes });
    }
};
